import React, { FC } from 'react';
import classNames from 'classnames';

const AstronautListCellLoading: FC<{ index: number }> = ({ index }) => (
  <div
    key={'loading-cell-' + index}
    className="astronaut_cell x"
    style={{
      borderTopWidth: 1,
      borderTopColor: 'black'
    }}
  >
    <div className="hide--m x">
      <div className="f fr pt075 pb025">
        <div className="mr1 f1 f fr aic">
          <div
            className={classNames('bau astronaut_cell__title bold mr05', {
              cw: index !== 0,
              cb: index === 0
            })}
          >
            {index === 0 ? 'Loading astronauts...' : '-'}
          </div>
        </div>
      </div>
      <div className="f fr py075">
        <div className="f fr f1">
          <div className="akkura small caps mr05">
            <br />
            <br />
          </div>
          <div className="f1 akkura small caps"></div>
        </div>
        <div className="f fr f1">
          <div className="akkura small caps mr05">
            <br />
            <br />
          </div>
          <div className="f1 akkura small caps"></div>
        </div>
      </div>
    </div>

    <div className="show--m py075 x">
      <div className="f fr aic">
        <div className={classNames('f3 h5 bau', { cw: index !== 0 })}>
          {index === 0 ? 'Loading astronauts...' : '-'}
        </div>
      </div>
    </div>
  </div>
);

export default AstronautListCellLoading;
