import React, { FC, useState } from 'react';
import classNames from 'classnames';
import {
  AstronautFilter,
  Craft,
  deriveAstronautFilterOptionState
} from 'superclient';
import MouseOver from '../../core/mouse-over';
import ImageSanity from '../image-sanity';

const FilterCraftList: FC<{
  field: AstronautFilter;
  options?: string[];
  craftMap?: Map<string, Craft>;
  remainingOptions?: string[];
  selectedOption?: string;
  onSelectOption: (field: AstronautFilter, option?: string) => void;
}> = ({
  field,
  options,
  craftMap,
  remainingOptions,
  selectedOption,
  onSelectOption
}) => {
  const [mouseOverIndex, setMouseOverIndex] = useState(-1);
  return (
    <>
      <div className="akkura small caps">{field}</div>
      <div className="x height-1 bcb" />
      <div className="f fr fw mt1">
        {options.map((option, i) => {
          const field = AstronautFilter.Craft;

          const craftName = option;
          const { blackIcon, filledBlackIcon } = craftMap.get(craftName);
          const { selected, selectable } = deriveAstronautFilterOptionState(
            remainingOptions,
            selectedOption,
            option
          );

          if (!blackIcon || !blackIcon.asset) {
            return <div />;
          }

          return (
            <div
              key={`${field}-${option}`}
              className={classNames(
                'astronaut_filters__craft rel mr075 mb075 f fr jcc',
                {
                  astronaut_filters__disabled: !selectable && !selected
                }
              )}
              onMouseEnter={() => setMouseOverIndex(i)}
              onMouseLeave={() => setMouseOverIndex(-1)}
            >
              <div
                className={classNames('astronaut_filters__craft__white rel', {
                  astronaut_filters__craft__white__selected: selected
                })}
                style={{
                  width: 25 * blackIcon.asset.metadata.dimensions.aspectRatio,
                  height: 25
                }}
              >
                <ImageSanity
                  className={classNames('abs x y top left', {
                    hide: mouseOverIndex === i
                  })}
                  image={blackIcon}
                  alt={craftName}
                  height={25}
                  width={25 * blackIcon.asset.metadata.dimensions.aspectRatio}
                />
              </div>
              <div
                className={classNames(
                  'astronaut_filters__craft__color abs fill pointer',
                  {
                    astronaut_filters__craft__color__selected: selected
                  }
                )}
                style={{
                  width: 25 * filledBlackIcon.asset.metadata.dimensions.aspectRatio,
                  height: 25
                }}
                onClick={() => onSelectOption(field, option)}
              >
                <ImageSanity
                  className="abs x y top left"
                  image={filledBlackIcon}
                  alt={craftName}
                  height={25}
                  width={25 * filledBlackIcon.asset.metadata.dimensions.aspectRatio}
                />
              </div>
              <MouseOver
                text={craftName}
                className={classNames('abs mt2', {
                  hide: mouseOverIndex !== i
                })}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FilterCraftList;
