export const filterWorker = () => {
  const AstronautFilter = {
    LifeForm: 'life form',
    Gender: 'gender',
    Nation: 'nation',
    Mission: 'missions',
    Craft: 'craft',
    OtherCrafts: 'otherCrafts'
  };

  const filterAstronauts = (
    astronauts,
    selectedFilterMap,
    sort,
    sortAscending,
    onlyShowAstronautsInSpace
  ) => {
    const remainingLifeFormOptions = [];
    const remainingGenderOptions = [];
    const remainingNationOptions = [];
    const remainingMissionOptions = [];
    const remainingCraftOptions = [];
    const remainingOtherCraftOptions = [];
    let inSpaceFilterSelectable = false;

    const totalHumanCount = astronauts.filter((astronaut) => {
      if (astronaut.astroNumber === 'FIRST FLIGHT') {
        return false;
      }
      return astronaut.speciesGroup === 'human';
    }).length;

    const totalNonHumanCount = astronauts.filter((astronaut) => {
      if (astronaut.astroNumber === 'FIRST FLIGHT') {
        return false;
      }
      return astronaut.speciesGroup !== 'human';
    }).length;

    const matches = astronauts.filter((astronaut) => {
      if (astronaut.astroNumber === 'FIRST FLIGHT') {
        return false;
      }
      if (onlyShowAstronautsInSpace && !astronaut.inSpace) {
        return false;
      }
      if (
        selectedFilterMap.get(AstronautFilter.LifeForm) !== undefined &&
        astronaut.speciesGroup !==
          selectedFilterMap.get(AstronautFilter.LifeForm)
      ) {
        return false;
      }
      if (
        selectedFilterMap.get(AstronautFilter.Gender) !== undefined &&
        astronaut.gender !== selectedFilterMap.get(AstronautFilter.Gender) &&
        selectedFilterMap.get(AstronautFilter.Gender) !== 'both'
      ) {
        return false;
      }
      if (
        selectedFilterMap.get(AstronautFilter.Nation) !== undefined &&
        !astronaut.nationsNames.includes(
          selectedFilterMap.get(AstronautFilter.Nation)
        )
      ) {
        return false;
      }
      if (
        selectedFilterMap.get(AstronautFilter.Mission) !== undefined &&
        !(astronaut.missionNames || []).includes(
          selectedFilterMap.get(AstronautFilter.Mission)
        )
      ) {
        return false;
      }
      if (
        selectedFilterMap.get(AstronautFilter.Craft) !== undefined &&
        !(astronaut.spacecraftNames || []).includes(
          selectedFilterMap.get(AstronautFilter.Craft)
        )
      ) {
        return false;
      }
      if (
        selectedFilterMap.get(AstronautFilter.OtherCrafts) !== undefined &&
        !(astronaut.spacecraftNames || []).includes(
          selectedFilterMap.get(AstronautFilter.OtherCrafts)
        )
      ) {
        return false;
      }

      if (astronaut.inSpace) {
        inSpaceFilterSelectable = true;
      }

      if (
        astronaut.speciesGroup &&
        !remainingLifeFormOptions.includes(astronaut.speciesGroup)
      ) {
        remainingLifeFormOptions.push(astronaut.speciesGroup);
      }
      if (
        astronaut.gender &&
        !remainingGenderOptions.includes(astronaut.gender)
      ) {
        remainingGenderOptions.push(astronaut.gender);
      }
      astronaut.nations.forEach((nation) => {
        if (!nation) return;
        const nationKey = nation.abbreviatedName || nation.name;
        if (!remainingNationOptions.includes(nationKey)) {
          remainingNationOptions.push(nationKey);
        }
      });
      (astronaut.missions || []).forEach((mission) => {
        if (mission) {
          if (
            mission.seriesName &&
            !remainingMissionOptions.includes(mission.seriesName)
          ) {
            remainingMissionOptions.push(mission.seriesName);
          }
          if (mission.spacecrafts) {
            mission.spacecrafts.forEach((craft) => {
              if (craft.whiteIcon && craft.blackIcon && craft.filledBlackIcon) {
                if (
                  craft.title &&
                  !remainingCraftOptions.includes(craft.title)
                ) {
                  remainingCraftOptions.push(craft.title);
                }
              } else if (
                craft.title &&
                !remainingOtherCraftOptions.includes(craft.title)
              ) {
                remainingOtherCraftOptions.push(craft.title);
              }
            });
          }
        }
      });

      return true;
    });

    let sorted = matches;
    if (sort !== undefined) {
      let field;
      switch (sort) {
        case 'launch order':
          field = 'overallNumber';
          break;
        case 'name':
          field = 'lastName';
          break;
        case 'nation':
          field = 'primaryNation';
          break;
        case 'days in space':
          field = 'sortTimeInSpace';
          break;
        case 'days spacewalking':
          field = 'sortSpacewalkTime';
          break;
        case 'spacewalks':
          field = 'spacewalkCount';
          break;
        case 'missions':
          field = 'missionCount';
          break;
        default:
          field = 'overallNumber';
          break;
      }

      if (field === 'lastName') {
        sorted = matches.sort((a, b) => {
          const contentA = a[field] || '';
          const contentB = b[field] || '';
          return contentA.localeCompare(contentB) * (sortAscending ? 1 : -1);
        });
      } else {
        sorted = matches.sort((a, b) => {
          const contentA = a[field] || 0;
          const contentB = b[field] || 0;
          if (sortAscending) {
            return contentA > contentB ? 1 : -1;
          }
          return contentA < contentB ? 1 : -1;
        });
      }

      const noFlights = sorted.filter((a) => a.astroNumber === 'N/A');
      sorted = sorted.filter((a) => a.astroNumber !== 'N/A').concat(noFlights);
    }

    const humanResultCount = sorted.filter((a) => a.speciesGroup === 'human')
      .length;
    const nonHumanResultCount = sorted.filter((a) => a.speciesGroup !== 'human')
      .length;

    const remainingFilterOptionsMap = new Map();
    remainingFilterOptionsMap.set(
      AstronautFilter.LifeForm,
      remainingLifeFormOptions
    );
    remainingFilterOptionsMap.set(
      AstronautFilter.Gender,
      remainingGenderOptions
    );
    remainingFilterOptionsMap.set(
      AstronautFilter.Nation,
      remainingNationOptions
    );
    remainingFilterOptionsMap.set(
      AstronautFilter.Mission,
      remainingMissionOptions
    );
    remainingFilterOptionsMap.set(AstronautFilter.Craft, remainingCraftOptions);
    remainingFilterOptionsMap.set(
      AstronautFilter.OtherCrafts,
      remainingOtherCraftOptions
    );

    return {
      totalHumanCount,
      totalNonHumanCount,
      humanResultCount,
      nonHumanResultCount,
      astronauts: sorted,
      remainingFilterOptionsMap,
      inSpaceFilterSelectable
    };
  };

  self.addEventListener('message', (e) => {
    if (!e) return;
    const {
      astronauts,
      selectedFilterMap,
      sortBy,
      sortAscending,
      onlyShowAstronautsInSpace
    } = e.data;

    const results = filterAstronauts(
      astronauts,
      selectedFilterMap,
      sortBy,
      sortAscending,
      onlyShowAstronautsInSpace
    );

    self.postMessage(
      {
        results
      },
      undefined
    );
  });
};
