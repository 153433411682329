import React, { FC, useState } from 'react';
import classNames from 'classnames';
import {
  AstronautFilter,
  deriveAstronautFilterOptionState,
  Nation
} from 'superclient';
import MouseOver from '../../core/mouse-over';
import ImageSanity from '../image-sanity';

const FilterDotList: FC<{
  field: AstronautFilter;
  options?: string[];
  nationMap: Map<string, Nation>;
  remainingOptions?: string[];
  selectedOption?: string;
  onSelectOption: (field: AstronautFilter, option?: string) => void;
}> = ({
  field,
  options,
  nationMap,
  remainingOptions,
  selectedOption,
  onSelectOption
}) => {
  const [mouseOverIndex, setMouseOverIndex] = useState(-1);
  return (
    <>
      <div className="akkura small caps">{field}</div>
      <div className="x height-1 bcb" />
      <div className="f fr fw mt1">
        {options.map((option, i) => {
          const field = AstronautFilter.Nation;

          const nationName = option;
          const nation = nationMap.get(option);
          if (!nation) return <></>;
          const flag = nation.flag;
          const { selected, selectable } = deriveAstronautFilterOptionState(
            remainingOptions,
            selectedOption,
            option
          );

          if (!flag || !flag.asset) {
            return <div />;
          }

          return (
            <div
              key={`${field}-${option}`}
              className={classNames(
                'astronaut_filters__flag_container mr05 mb05 p1px f fc aic pointer',
                {
                  astronaut_filters__flag_container__selected: selected
                }
              )}
              onClick={() => onSelectOption(field, option)}
              onMouseEnter={() => setMouseOverIndex(i)}
              onMouseLeave={() => setMouseOverIndex(-1)}
            >
              <div className={classNames('p015 bcbl')}>
                <div
                  className="rel"
                  style={{
                    width: 25 * flag.asset.metadata.dimensions.aspectRatio,
                    height: 25
                  }}
                >
                  <ImageSanity
                    className={classNames('abs x y top left', {
                      astronaut_filters__disabled: !selectable && !selected
                    })}
                    image={flag}
                    alt={nationName}
                    width={200}
                  />
                </div>
              </div>
              <MouseOver
                text={nationName}
                className={classNames('abs mt2', {
                  hide: mouseOverIndex !== i
                })}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FilterDotList;
