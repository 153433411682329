import React, { FC } from 'react';
import SuperCard, { ListTypes, SuperCardProps } from '../core/super-card';

const AstronautCardLoading: FC<{ index: number } & SuperCardProps> = ({
  index,
  listType = ListTypes.CardGrid,
  className,
  onClick
}) => (
  <SuperCard
    key={'loading-grid-' + index}
    listType={listType}
    className={className}
  >
    {index !== 0 ? (
      <div className="bcg">
        <div className="astronaut_card__image rel bcdg"></div>
        <div className="astronaut_card__bio"></div>
      </div>
    ) : (
      <div className="h2 cb">Astronauts Loading...</div>
    )}
  </SuperCard>
);

export default AstronautCardLoading;
