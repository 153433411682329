import { useEffect, useRef, useState } from 'react';

export const useIntersect = (
  root = null,
  rootMargin = '0px',
  threshold = 0
): [(node: HTMLElement) => void, IntersectionObserverEntry | undefined] => {
  const [entry, updateEntry] = useState<IntersectionObserverEntry>();
  const [node, setNode] = useState<HTMLElement>();

  const observerRef = useRef<IntersectionObserver>();

  useEffect(() => {
    if (observerRef.current) observerRef.current?.disconnect();

    const _observer = new window.IntersectionObserver(
      ([entry]) => updateEntry(entry),
      { root, rootMargin, threshold }
    );

    observerRef.current = _observer;

    if (node) observerRef.current.observe(node);

    return () => observerRef.current?.disconnect();
  }, [node, root, rootMargin, threshold]);

  return [setNode, entry];
};
