import React from 'react';

export default function GridIcon({ className = '' }) {
  return (
    <svg
      className={className}
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
    >
      <g
        id="Page-3"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      >
        <g
          id="supercluster_database_05-21-2021"
          transform="translate(-54.000000, -110.000000)"
          stroke="#000000"
        >
          <path
            d="M64,129 L73,129 L73,120 L64,120 L64,129 Z M55,129 L64,129 L64,120 L55,120 L55,129 Z M64,120 L73,120 L73,111 L64,111 L64,120 Z M55,120 L64,120 L64,111 L55,111 L55,120 Z"
            id="grid-icon"
          ></path>
        </g>
      </g>
    </svg>
  );
}
