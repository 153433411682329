import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import {
  abbreviateDuration,
  Astronaut,
  deriveAstronautIsAnimal,
  deriveAstronautMillisInSpace,
  deriveAstronautMillisSpacewalking,
  deriveAstronautTimeInSpace,
  deriveAstronautTimeSpacewalking
} from 'superclient';
import MouseOver from '../core/mouse-over';
import ImageSanity from '../core/image-sanity';

const AstronautListCell: FC<{ astronaut: Astronaut; paramString: string }> = ({
  astronaut,
  paramString
}) => {
  const isAnimal = deriveAstronautIsAnimal(astronaut);
  const totalSpaceDuration = deriveAstronautMillisInSpace(astronaut);
  const totalSpacewalkDuration = deriveAstronautMillisSpacewalking(astronaut);

  const spacewalks = [];
  for (let s = 0; s < astronaut.spacewalkCount; s++) {
    spacewalks.push('sw' + s);
  }

  const [mouseOverNation, setMouseOverNation] = useState(-1);

  return (
    <Link
      key={astronaut._id}
      to={`/astronauts/${astronaut.slug.current}${paramString}`}
      className="astronaut_cell x"
      style={{
        borderTopWidth: 1,
        borderTopColor: 'black'
      }}
    >
      <div className="astronaut_cell__mobile hide--m x">
        <div className="f fr pt075 pb025">
          <div className="mr1 f1 f fr aic">
            <div className="bau astronaut_cell__title bold mr05">
              {!isAnimal && [astronaut.lastName, astronaut.firstName].join(', ')}
              {isAnimal && astronaut.name}
            </div>
            {astronaut.nations &&
              astronaut.nations.map((nation) => {
                return nation.flag ? (
                  <div
                    key={nation._id}
                    className="astronaut_cell__flag rel mr05 mt025"
                    style={{
                      width: 15 * nation.flag.asset.metadata.dimensions.aspectRatio,
                      height: 15
                    }}
                  >
                    <ImageSanity
                      className="abs x y top left"
                      image={nation.flag}
                      alt={nation.name}
                      width={45}
                    />
                  </div>
                ) : (
                  <div />
                );
              })}
          </div>
          <div
            className={cx('py05 px05', {
              bcg: !astronaut.inSpace,
              bcbl: astronaut.inSpace
            })}
          >
            <div
              className={cx('f1 h5 bau', {
                cw: astronaut.inSpace,
                cb: !astronaut.inSpace
              })}
            >
              {astronaut.astroNumber}
            </div>
          </div>
        </div>
        <div className="f fr py075">
          <div className="f fr f1">
            <div className="akkura small caps mr05">
              MISSIONS: <br />
              SPACEWALKS:
            </div>
            <div className="f1 akkura small caps">
              {astronaut.missionCount}
              <br />
              {astronaut.spacewalkCount}
            </div>
          </div>
          <div className="f fr f1">
            <div className="akkura small caps mr05">
              DAYS IN SPACE: <br />
              DAYS SPACEWALKING:
            </div>
            <div className="f1 akkura small caps">
              {astronaut.totalMinutesInSpace !== undefined
                ? abbreviateDuration(totalSpaceDuration)
                : '?'}
              <br />
              {astronaut.totalSecondsSpacewalking !== undefined
                ? abbreviateDuration(totalSpacewalkDuration)
                : '?'}
            </div>
          </div>
        </div>
      </div>

      <div className="astronaut_cell__desktop show--m py075 x">
        <div className="f fr aic">
          <div className={cx('f1 rel h5 bau', { cbl: astronaut.inSpace })}>
            {astronaut.inSpace && (
              <img
                src="/orbit-blue.gif"
                className="abs top bottom"
                style={{
                  left: '10px',
                  width: '29px',
                  height: '29px',
                  top: '-6px'
                }}
              />
            )}
            {astronaut.astroNumber}
          </div>
          <div className="f3 h5 bau">
            {!isAnimal && [astronaut.lastName, astronaut.firstName].join(', ')}
            {isAnimal && astronaut.name}
          </div>
          <div className="f15 h5 bau caps f fr">
            {astronaut.nations &&
              astronaut.nations.map((nation, i) => {
                return nation.flag ? (
                  <div
                    key={nation._id}
                    className="astronaut_cell__flag rel mr05 f fr jcc"
                    style={{
                      width: 20 * nation.flag.asset.metadata.dimensions.aspectRatio,
                      height: 20
                    }}
                    onMouseEnter={() => setMouseOverNation(i)}
                    onMouseLeave={() => setMouseOverNation(-1)}
                  >
                    <ImageSanity
                      className="abs x y top left"
                      image={nation.flag}
                      alt={nation.name}
                      width={100}
                    />
                    <MouseOver
                      textColor="white"
                      backgroundColor="#241F21"
                      text={nation.name}
                      className={cx('abs mt1', {
                        hide: mouseOverNation !== i
                      })}
                      style={{
                        marginTop:
                          30 / nation.flag.asset.metadata.dimensions.aspectRatio
                      }}
                    />
                  </div>
                ) : (
                  <div />
                );
              })}
          </div>
          <div className="f2 h5 bau">{deriveAstronautTimeInSpace(astronaut)}</div>
          <div className="f2 f fr fw">
            {spacewalks.map((s) => (
              <div className="mission-icon" key={s}>
                <div className="mission-icon__inner" />
                <div className="mission-icon__inner" />
                <div className="mission-icon__inner" />
                <div className="mission-icon__inner" />
              </div>
            ))}
          </div>
          <div className="f2 h5 bau">
            {deriveAstronautTimeSpacewalking(astronaut)}
          </div>
          <div className="f2 f fr">
            {astronaut.missions &&
              astronaut.missions.map((m) => (
                <div className="spacewalk-icon" key={m._id}>
                  <div className="spacewalk-icon__inner" />
                </div>
              ))}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default React.memo(AstronautListCell);
