import React from 'react';

export default function ClearCircle({ className = '' }) {
  return (
    <svg
      className={className}
      width="20px"
      height="20px"
      viewBox="0 0 17 17"
      version="1.1"
    >
      <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="supercluster_database_05-21-2021"
          transform="translate(-59.000000, -78.000000)"
          stroke="#000000"
          strokeWidth="0.75"
        >
          <g id="plus" transform="translate(59.999900, 78.829200)">
            <line
              x1="7.794"
              y1="11.6606"
              x2="7.794"
              y2="3.9256"
              id="Stroke-42"
            ></line>
            <line
              x1="3.9253"
              y1="7.7931"
              x2="11.6603"
              y2="7.7931"
              id="Stroke-43"
            ></line>
            <path
              d="M7.793,15.586 C12.097,15.586 15.586,12.097 15.586,7.793 C15.586,3.489 12.097,0 7.793,0 C3.489,0 3.55271368e-15,3.489 3.55271368e-15,7.793 C3.55271368e-15,12.097 3.489,15.586 7.793,15.586 Z"
              id="Stroke-59"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
