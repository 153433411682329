import React, { FC } from 'react';
import classNames from 'classnames';
import {
  AstronautFilter,
  deriveAstronautFilterOptionDisplayName,
  deriveAstronautFilterOptionState
} from 'superclient';

const FilterDotList: FC<{
  title: string;
  field: AstronautFilter;
  options?: string[];
  remainingOptions?: string[];
  selectedOption?: string;
  onSelectOption: (field: AstronautFilter, option?: string) => void;
}> = ({
  title,
  field,
  options,
  remainingOptions,
  selectedOption,
  onSelectOption
}) => {
  return (
    <>
      <div className="akkura small caps">{title}</div>
      <div className="x height-1 bcb" />
      <div className="f fr fw mt05">
        {options.map((option) => {
          const { selected, selectable } = deriveAstronautFilterOptionState(
            remainingOptions,
            selectedOption,
            option
          );

          return (
            <div
              key={`${field}_${option}`}
              className={classNames(
                'astronaut_filters__dropdown_option__expanded f fr aic small akkura caps mr15 mb015 pointer',
                {
                  astronaut_filters__dropdown_option__expanded__disabled:
                    !selectable && !selected
                }
              )}
              onClick={() => onSelectOption(field, option)}
            >
              <div
                className={classNames('astronaut_filters__dot', {
                  bcb: selected
                })}
              />
              <span className="ml1 pl025">
                {deriveAstronautFilterOptionDisplayName(field, option).toUpperCase()}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FilterDotList;
