import React, { FC, useState, useCallback } from 'react';
import classNames from 'classnames';
import {
  AstronautFilter,
  AstronautSortOption,
  deriveAstronautFilterOptionDisplayName
} from 'superclient';
import GridIcon from '../svgs/gridIcon.js';
import ListIcon from '../svgs/listIcon.js';
import ClearCircle from '../svgs/clearCircle.js';
import TinyClearCircle from '../svgs/tinyClearCircle.js';

const AstronautSortBar: FC<{
  sortOptions: AstronautSortOption[];
  sortBy: AstronautSortOption;
  sortAscending: boolean;
  selectedFiltersMap: Map<AstronautFilter, string>;
  humanResultCount?: number;
  nonHumanResultCount?: number;
  useList?: boolean;
  searchText?: string;
  onSearchTextChange: (searchText: string) => void;
  onToggleUseList: (value: boolean) => void;
  onSortChange: (sortBy: AstronautSortOption, sortAscending: boolean) => void;
  onFilterChange: (selectedFiltersMap: Map<AstronautFilter, string>) => void;
  onReset: () => void;
}> = ({
  sortOptions,
  sortBy,
  sortAscending,
  selectedFiltersMap,
  humanResultCount,
  nonHumanResultCount,
  useList,
  searchText = '',
  onSearchTextChange,
  onToggleUseList,
  onSortChange,
  onFilterChange,
  onReset
}) => {
  const [open, setOpen] = useState(false);

  const onRemoveFilter = useCallback(
    (field: AstronautFilter) => {
      const updatedFilters = new Map(selectedFiltersMap);
      updatedFilters.delete(field);
      onFilterChange(updatedFilters);
    },
    [selectedFiltersMap]
  );

  const onSelectOption = useCallback(
    (option: AstronautSortOption) => {
      if (sortBy === option) {
        onSortChange(option, !sortAscending);
      } else {
        onSortChange(option, sortAscending);
      }
    },
    [sortBy, sortAscending]
  );

  return (
    <>
      <div
        className={'astronaut_filters__subheader astronauts_subheader f aic bcg z5'}
      >
        <div className="container--xl mxa f fr x py025 aifs px15">
          <div className="gridtoggle f fr aic py025">
            <div className="mr1 clickable f" onClick={() => onToggleUseList(false)}>
              <GridIcon />
            </div>
            <div
              className={classNames('astronaut_filters__switch pointer f', {
                jce: useList
              })}
              onClick={() => onToggleUseList(!useList)}
            >
              <div className="astronaut_filters__switch_bubble" />
            </div>
            <div className="ml1 clickable f" onClick={() => onToggleUseList(true)}>
              <ListIcon />
            </div>
          </div>

          <div className="ml2 f fc jcc aie mt025">
            <div className="bau caps small">
              {`Showing `}
              <u>{humanResultCount}</u>
              {` human${humanResultCount === 1 ? '' : 's'} & `}
              <u>{nonHumanResultCount}</u>
              {` non-human${nonHumanResultCount === 1 ? '' : 's'}`}
            </div>
          </div>

          <div className="mx1 f fr fw aic f1">
            {[...selectedFiltersMap.keys()].map((field, i) => (
              <div key={`crumb-${i}`} className="f fr aic">
                <div className="akkura small caps mt025 ml1">
                  {i > 0 && <span className="mr1 akkura small mt025">/</span>}
                  {deriveAstronautFilterOptionDisplayName(
                    field,
                    selectedFiltersMap.get(field)
                  )}
                </div>
                <div
                  className="pl05 pb015 clickable"
                  onClick={() => onRemoveFilter(field)}
                >
                  <TinyClearCircle />
                </div>
              </div>
            ))}
          </div>

          <div className="f fr aic clickable py015" onClick={onReset}>
            <div className="bau caps h5 mt025">RESET</div>
            <ClearCircle className="ml05 mt015" />
          </div>
        </div>
      </div>

      <div className="astronaut_index__sort astronauts_subheader container--xl mxa f fr fw jcb jcc bcw">
        {useList && (
          <div className="show--m x px15">
            <div className="x f fr pt075 astronaut_index__border_bottom">
              {sortOptions.map((o, i) => (
                <div
                  key={'ds-' + o}
                  className={`${
                    ['f1', 'f3', 'f15', 'f2', 'f2', 'f2', 'f2'][i]
                  } akkura xsmall caps clickable f fr aic`}
                  onClick={() => onSelectOption(o)}
                >
                  <div
                    className={classNames('', {
                      'mt025 astronaut_index__descending': !sortAscending,
                      hide: sortBy !== o
                    })}
                  >
                    <span className={'astronaut_index__chevron'} />
                  </div>
                  {o}
                </div>
              ))}
            </div>
          </div>
        )}
        {!useList && (
          <div className="show--m x astronaut_index__border_bottom mx15">
            <div className="x f fr fw py075 aie jcb">
              <div className="mr2 akkura small caps">Sort By:</div>
              {sortOptions.map((o) => (
                <div
                  key={o}
                  className="ml2 mt025 akkura xsmall caps clickable ar rel f fr"
                  onClick={() => onSelectOption(o)}
                >
                  <div
                    className={classNames('', {
                      astronaut_index__descending: !sortAscending,
                      hide: sortBy !== o
                    })}
                  >
                    <span className={'astronaut_index__chevron'} />
                  </div>
                  {o}
                  {sortBy === o && (
                    <div className="abs bottom x bcb height-1 left" />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="astronaut_sort astronauts_subheader hide--m">
        <div className="x bcg f fr aic px05 pointer" onClick={() => setOpen(!open)}>
          <div
            className={`astronaut_sort__toggle ${
              open ? 'astronaut_sort__toggle__open' : ''
            }`}
          >
            <div className="astronaut_sort__chevron">
              <span />
            </div>
          </div>
          <div className="ml1 mt025 akkura small caps">SORT BY</div>
        </div>
        <div
          className={`z5 astronaut_sort__dropdown x bcg f fc ${
            open ? 'astronaut_sort__dropdown__open' : ''
          }`}
        >
          <div className="px15 pt025 pb15">
            {sortOptions.map((option) => (
              <div
                key={'sort-option-' + option}
                className="f aifs clickable"
                onClick={() => {
                  if (option === AstronautSortOption.LaunchOrder) {
                    if (option === sortBy) {
                      onSortChange(option, !sortAscending);
                    } else {
                      onSortChange(option, true);
                    }
                  } else if (option === sortBy) {
                    onSortChange(option, !sortAscending);
                  } else {
                    onSortChange(option, sortAscending);
                  }
                }}
              >
                <div
                  className={`${
                    option === sortBy ? 'astronaut_sort__option__selected' : ''
                  } f fr py05 pr075`}
                >
                  <div className="astronaut_sort__direction_container">
                    <div
                      className={
                        'astronaut_sort__direction_toggle' +
                        (option === sortBy ? '' : ' hide') +
                        (sortAscending ? '' : ' astronaut_sort__descending')
                      }
                    >
                      <span />
                    </div>
                  </div>
                  <div className="ml1 akkura small caps">{option}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="hide--m mx15 z4">
          <div className="f fr jcb aic my05">
            <div className="f fr">
              <div
                className="clickable f mr1"
                onClick={() => onToggleUseList(false)}
              >
                <GridIcon />
              </div>
              <div
                className={classNames('astronaut_filters__switch pointer f', {
                  jce: useList
                })}
                onClick={() => onToggleUseList(!useList)}
              >
                <div className="astronaut_filters__switch_bubble" />
              </div>
              <div className="ml1 clickable f" onClick={() => onToggleUseList(true)}>
                <ListIcon />
              </div>
            </div>

            <div className="astronaut_filters__search_container ml2 rel f1 f fr">
              <div className="astronaut_filters__searchicon__top">
                <div className="astronaut_filters__searchicon__top__lens" />
                <div className="astronaut_filters__searchicon__top__handle" />
              </div>
              <input
                className="astronaut_filters__searchfield mr05 small akkura caps"
                type="text"
                placeholder="SEARCH NAMES"
                value={searchText}
                onChange={(e) => onSearchTextChange(e.target.value)}
              />
              <div
                className={classNames('clickable')}
                style={{ opacity: searchText.length === 0 ? 0 : 1 }}
                onClick={() => onSearchTextChange('')}
              >
                <ClearCircle className="mx025 mt025" />
              </div>
            </div>

            <div className="abs bottom left x height-1 bcb" />
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(AstronautSortBar);
