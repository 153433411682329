import classNames from 'classnames';
import React, { FC, useState, useCallback } from 'react';
import {
  AstronautFilter,
  Craft,
  deriveAstronautFilterOptionDisplayName,
  Nation
} from 'superclient';
import FilterDotList from '../core/filters/filter-dot-list';
import FilterNationList from '../core/filters/filter-nation-list';
import FilterCraftList from '../core/filters/filter-craft-list';

import PlusCircle from '../svgs/plusCircle.js';
import TinyClearCircle from '../svgs/tinyClearCircle.js';
import ClearCircle from '../svgs/clearCircle.js';

const optionState = (
  availableOptions: string[],
  selectedOption: string | undefined,
  option: string
): { selected: boolean; selectable: boolean } => {
  const selected =
    selectedOption === option || (selectedOption === undefined && option === 'All');
  const selectable = availableOptions.includes(option) || option === 'All';
  return { selected, selectable };
};

const AstronautFiltersBar: FC<{
  open: boolean;
  searchText?: string;
  filterOptionMap?: Map<AstronautFilter, string[]>;
  selectedFiltersMap: Map<AstronautFilter, string>;
  remainingFilterOptionsMap?: Map<AstronautFilter, string[]>;
  onlyShowAstronautsInSpace: boolean;
  inSpaceFilterSelectable?: boolean;
  craftMap?: Map<string, Craft>;
  nationMap?: Map<string, Nation>;
  totalHumanCount?: number;
  totalNonHumanCount?: number;
  humanResultCount?: number;
  nonHumanResultCount?: number;
  onReset: () => void;
  onToggleOpen: (open: boolean) => void;
  onSearchTextChange: (searchText: string) => void;
  onToggleOnlyShowAstronautsInSpace: (value: boolean) => void;
  onSelectionChange: (selectedFiltersMap: Map<AstronautFilter, string>) => void;
}> = ({
  open,
  searchText = '',
  filterOptionMap,
  selectedFiltersMap,
  remainingFilterOptionsMap,
  onlyShowAstronautsInSpace,
  inSpaceFilterSelectable,
  craftMap,
  nationMap,
  totalHumanCount,
  totalNonHumanCount,
  humanResultCount,
  nonHumanResultCount,
  onReset,
  onToggleOpen,
  onSearchTextChange,
  onToggleOnlyShowAstronautsInSpace,
  onSelectionChange
}) => {
  const [expandedDropdown, setExpandedDropdown] = useState(-1);
  const [mouseOverOrbit, setMouseOverOrbit] = useState(false);

  const filterFields = filterOptionMap ? Array.from(filterOptionMap.keys()) : [];
  const selectedFilterFields = Array.from(selectedFiltersMap.keys());

  const onSelectOption = useCallback(
    (field: AstronautFilter, option?: string) => {
      const currentSelection = selectedFiltersMap.get(field);
      if (currentSelection === option || option === 'All' || option === undefined) {
        const updatedFilters = new Map(selectedFiltersMap);
        updatedFilters.delete(field);
        onSelectionChange(updatedFilters);
      } else {
        const updatedFilters = new Map(selectedFiltersMap);
        updatedFilters.set(field, option);
        onSelectionChange(updatedFilters);
      }
    },
    [selectedFiltersMap]
  );

  const onRemoveFilter = useCallback(
    (field: AstronautFilter) => {
      const updatedFilters = new Map(selectedFiltersMap);
      updatedFilters.delete(field);
      onSelectionChange(updatedFilters);
    },
    [selectedFiltersMap]
  );

  // if (!filterOptionMap || !remainingFilterOptionsMap) {
  //   return (
  //     <div className="astronaut_filters">
  //       <div className="astronaut_filters__container"></div>
  //     </div>
  //   );
  // }

  return (
    <div className="astronaut_filters">
      <div className="astronaut_filters__container astronauts_subheader">
        <div className="astronaut_filters__primarybar container--xl mxa f fr aic px15">
          <div
            className={`f aic jcc y clickable astronaut_filters__toggle ${
              open ? 'astronaut_filters__toggle__open' : ''
            }`}
            onClick={() => {
              const newVal = !open;
              onToggleOpen(newVal);
            }}
          >
            <PlusCircle />
          </div>
          <div className="astronaut_filters__mobile_breadcrumbs ml1 f fr fw jce aic f1">
            <div className="f1" />
            {onlyShowAstronautsInSpace && (
              <div className="f fr aic">
                <div className="akkura small caps mt025">In Space</div>
              </div>
            )}
            {selectedFilterFields.map((field, i) => {
              const option = selectedFiltersMap.get(field);
              if (!option) return null;

              return (
                <div className="f fr aic" key={`crumb` + i}>
                  <div className="akkura small caps mt025">
                    {(i > 0 || onlyShowAstronautsInSpace) && (
                      <span className="mx1 akkura small mt025">/</span>
                    )}
                    {deriveAstronautFilterOptionDisplayName(
                      field,
                      selectedFilterFields[field]
                    )}
                  </div>
                </div>
              );
            })}
            <div
              className={classNames('y pl05 pb015 clickable', {
                hide: selectedFilterFields.length === 0
              })}
              onClick={onReset}
            >
              <TinyClearCircle />
            </div>
          </div>
          <div
            className={classNames('f fr aic jcc ml1 bcw', {
              astronaut_filters__disabled: !inSpaceFilterSelectable,
              clickable: inSpaceFilterSelectable
            })}
            onClick={() =>
              onToggleOnlyShowAstronautsInSpace(!onlyShowAstronautsInSpace)
            }
            onMouseEnter={() => {
              if (inSpaceFilterSelectable) {
                setMouseOverOrbit(true);
              }
            }}
            onMouseLeave={() => setMouseOverOrbit(false)}
            style={{
              height: '30px',
              width: '86px',
              borderRadius: '50px',
              filter: onlyShowAstronautsInSpace ? 'invert(1)' : 'none'
            }}
          >
            <div className="akkura small caps rel ml05 mr05">
              <img
                src="/orbit-still-without-bars.png"
                className={classNames('abs top bottom', {
                  hide: onlyShowAstronautsInSpace || mouseOverOrbit
                })}
                style={{
                  width: '29px',
                  height: '29px',
                  top: '-6px',
                  marginTop: '2px'
                }}
              />
              <img
                src="/orbit-without-bars.gif"
                className={classNames('abs top bottom', {
                  hide: !(onlyShowAstronautsInSpace || mouseOverOrbit)
                })}
                style={{
                  width: '29px',
                  height: '29px',
                  top: '-6px',
                  marginTop: '2px'
                }}
              />
              <div style={{ marginTop: '1px' }}>In Space</div>
            </div>
          </div>
          <div className="astronaut_filters__top f fr fa aic">
            <div className="astronaut_filters__search_container mx1 rel f fr">
              <div className="astronaut_filters__searchicon__top">
                <div className="astronaut_filters__searchicon__top__lens" />
                <div className="astronaut_filters__searchicon__top__handle" />
              </div>
              <input
                className="astronaut_filters__searchfield mr05 small akkura caps"
                type="text"
                placeholder="SEARCH NAMES"
                value={searchText}
                onChange={(e) => onSearchTextChange(e.target.value)}
              />
              <div
                className="clickable"
                style={{ opacity: searchText.length === 0 ? 0 : 1 }}
                onClick={() => onSearchTextChange('')}
              >
                <ClearCircle className="mx025 mt025" />
              </div>
            </div>
            {open ? (
              <>
                <div className="f1" />
                <div
                  className={classNames('ml1 f fc jcc aie', {
                    hide: !open
                  })}
                >
                  <div className="bau caps xsmall mt015">{'humans:'}</div>
                  <div className="bau caps xsmall mt015">{'non-humans:'}</div>
                </div>
                <div
                  className={classNames('ml05 f fc jcc aie', {
                    hide: !open
                  })}
                >
                  <div className="bau caps xsmall mt015">{humanResultCount}</div>
                  <div className="bau caps xsmall mt015">{nonHumanResultCount}</div>
                </div>
                <div className={classNames('mx025 f fc jcc', { hide: !open })}>
                  <div className="bau caps xsmall mt015">/</div>
                  <div className="bau caps xsmall mt015">/</div>
                </div>
                <div className={classNames('f fc jcc', { hide: !open })}>
                  <div className="bau caps xsmall mt015">{totalHumanCount}</div>
                  <div className="bau caps xsmall mt015">{totalNonHumanCount}</div>
                </div>
              </>
            ) : (
              <>
                {filterFields.length === 0 &&
                  Object.values(AstronautFilter)
                    .slice(0, 5)
                    .map((filterField) => (
                      <div
                        key={filterField}
                        className="astronaut_filters__dropdown_container f fa"
                      >
                        <div className="astronaut_filters__dropdown f fa aic">
                          <div className="fa small akkura caps">{filterField}</div>
                          <div className="astronaut_filters__dropdown_arrow" />
                        </div>
                        <div className={'astronaut_filters__dropdown_options'}></div>
                      </div>
                    ))}
                {filterFields.slice(0, 5).map((filterField, index) => {
                  const options = filterOptionMap.get(filterField);
                  const selectedOption = selectedFiltersMap.get(filterField);
                  return (
                    <div
                      key={filterField}
                      className={classNames(
                        'astronaut_filters__dropdown_container f fa',
                        {
                          astronaut_filters__dropdown_container__open:
                            expandedDropdown === index,
                          astronaut_filters__hidden: open
                        }
                      )}
                      onMouseLeave={() => {
                        setExpandedDropdown(-1);
                      }}
                    >
                      <div
                        className="astronaut_filters__dropdown f fa aic"
                        onClick={() => {
                          setExpandedDropdown(
                            expandedDropdown === index ? -1 : index
                          );
                        }}
                      >
                        <div className="fa small akkura caps">{filterField}</div>
                        <div className="astronaut_filters__dropdown_arrow" />
                      </div>
                      <div
                        className={classNames(
                          'astronaut_filters__dropdown_options',
                          {
                            astronaut_filters__dropdown_options_open:
                              expandedDropdown === index
                          }
                        )}
                      >
                        {remainingFilterOptionsMap &&
                          ['All'].concat(options).map((option) => {
                            const { selected, selectable } = optionState(
                              remainingFilterOptionsMap.get(filterField),
                              selectedOption,
                              option
                            );

                            return (
                              <div
                                key={`${filterField}_${option}`}
                                className={classNames(
                                  'astronaut_filters__dropdown_option f fr aic small akkura caps mx15 my05 pointer',
                                  {
                                    astronaut_filters__dropdown_option__disabled: !selectable
                                  }
                                )}
                                onClick={() => onSelectOption(filterField, option)}
                              >
                                <div
                                  className={classNames('astronaut_filters__dot', {
                                    bcb: selected
                                  })}
                                />
                                {deriveAstronautFilterOptionDisplayName(
                                  filterField,
                                  option
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className={classNames(
          'astronaut_filters__bigContainer astronauts_subheader',
          {
            astronaut_filters__bigContainer__open: open
          }
        )}
      >
        {filterOptionMap && remainingFilterOptionsMap && (
          <div className="container--xl mxa f fc px1">
            <div className="x f fr aic">
              <div className="mr15 bau caps h5 mt05 asfs">
                {selectedFilterFields.length > 0 && 'Displaying:'}
              </div>
              <div className="mr1 f1 f fr fw aic mt015">
                {selectedFilterFields.map((field, i) => (
                  <div key={`crumb-${i}`} className="f fr aic">
                    <div className="akkura small caps mt025">
                      {i > 0 && <span className="mx1 akkura small mt05">/</span>}
                      {deriveAstronautFilterOptionDisplayName(
                        field,
                        selectedFiltersMap.get(field)
                      )}
                    </div>
                    <div
                      className="y px05 clickable"
                      style={{ paddingBottom: '0.1em' }}
                      onClick={() => onRemoveFilter(field)}
                    >
                      <TinyClearCircle />
                    </div>
                  </div>
                ))}
              </div>
              <div className="f fr aic clickable mt025 asfs" onClick={onReset}>
                <div className="bau caps h5 mt025">RESET</div>
                <ClearCircle className="ml05 mt015" />
              </div>
            </div>

            <div
              className={classNames('f fr aic mt1 mb05', {
                astronaut_filters__disabled: !inSpaceFilterSelectable,
                clickable: inSpaceFilterSelectable
              })}
              onClick={() =>
                onToggleOnlyShowAstronautsInSpace(!onlyShowAstronautsInSpace)
              }
              onMouseEnter={() => {
                if (inSpaceFilterSelectable) {
                  setMouseOverOrbit(true);
                }
              }}
              onMouseLeave={() => setMouseOverOrbit(false)}
            >
              <div className="mr05 akkura small caps rel">
                <img
                  src="/orbit-still.png"
                  className={classNames('abs top bottom', {
                    hide: onlyShowAstronautsInSpace || mouseOverOrbit
                  })}
                  style={{
                    width: '29px',
                    height: '29px',
                    top: '-6px'
                  }}
                />
                <img
                  src="/orbit.gif"
                  className={classNames('abs top bottom', {
                    hide: !(onlyShowAstronautsInSpace || mouseOverOrbit)
                  })}
                  style={{
                    width: '29px',
                    height: '29px',
                    top: '-6px'
                  }}
                />
                In Space
              </div>
              <div
                className={classNames('astronaut_filters__space_dot', {
                  bcb: onlyShowAstronautsInSpace
                })}
              />
            </div>

            <div className="x mt1 f fr">
              <div className="f f2 fc mr2">
                <FilterDotList
                  title="Life Form"
                  field={AstronautFilter.LifeForm}
                  options={filterOptionMap.get(AstronautFilter.LifeForm)}
                  remainingOptions={remainingFilterOptionsMap?.get(
                    AstronautFilter.LifeForm
                  )}
                  selectedOption={selectedFiltersMap.get(AstronautFilter.LifeForm)}
                  onSelectOption={onSelectOption}
                />
              </div>
              <div className="f f1 fc">
                <FilterDotList
                  title="Gender"
                  field={AstronautFilter.Gender}
                  options={filterOptionMap.get(AstronautFilter.Gender)}
                  remainingOptions={remainingFilterOptionsMap?.get(
                    AstronautFilter.Gender
                  )}
                  selectedOption={selectedFiltersMap.get(AstronautFilter.Gender)}
                  onSelectOption={onSelectOption}
                />
              </div>
            </div>

            <div className="f x mt1 fc">
              <FilterNationList
                field={AstronautFilter.Nation}
                options={filterOptionMap.get(AstronautFilter.Nation)}
                nationMap={nationMap}
                remainingOptions={remainingFilterOptionsMap?.get(
                  AstronautFilter.Nation
                )}
                selectedOption={selectedFiltersMap.get(AstronautFilter.Nation)}
                onSelectOption={onSelectOption}
              />
            </div>

            <div className="f x mt1 fc">
              <FilterDotList
                title="Mission"
                field={AstronautFilter.Mission}
                options={filterOptionMap.get(AstronautFilter.Mission)}
                remainingOptions={remainingFilterOptionsMap?.get(
                  AstronautFilter.Mission
                )}
                selectedOption={selectedFiltersMap.get(AstronautFilter.Mission)}
                onSelectOption={onSelectOption}
              />
            </div>

            <div className="f x mt1 fc">
              <FilterCraftList
                field={AstronautFilter.Craft}
                options={filterOptionMap.get(AstronautFilter.Craft)}
                craftMap={craftMap}
                remainingOptions={remainingFilterOptionsMap?.get(
                  AstronautFilter.Craft
                )}
                selectedOption={selectedFiltersMap.get(AstronautFilter.Craft)}
                onSelectOption={onSelectOption}
              />
            </div>

            <div className="f x mt1 mb2 fc">
              <FilterDotList
                title="Other Crafts"
                field={AstronautFilter.OtherCrafts}
                options={filterOptionMap.get(AstronautFilter.OtherCrafts)}
                remainingOptions={remainingFilterOptionsMap?.get(
                  AstronautFilter.OtherCrafts
                )}
                selectedOption={selectedFiltersMap.get(AstronautFilter.OtherCrafts)}
                onSelectOption={onSelectOption}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(AstronautFiltersBar);
