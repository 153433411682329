import React from 'react';

export default function TinyClearCircle({ className = '' }) {
  return (
    <svg
      className={className}
      width="10px"
      height="10px"
      viewBox="0 0 10 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="supercluster_database_05-21-2021"
          transform="translate(-879.000000, -113.000000)"
          stroke="#000000"
        >
          <g id="tinyclear" transform="translate(879.000000, 113.000000)">
            <g
              id="Group"
              transform="translate(5.000050, 5.000000) rotate(45.000000) translate(-5.000050, -5.000000) translate(2.500050, 2.500000)"
            >
              <line x1="2.5" y1="5" x2="2.5" y2="0" id="Stroke-42"></line>
              <line x1="0" y1="2.5" x2="5" y2="2.5" id="Stroke-43"></line>
            </g>
            <path
              d="M5,9.5 C7.48531021,9.5 9.5,7.48531021 9.5,5 C9.5,2.51468979 7.48531021,0.5 5,0.5 C2.51468979,0.5 0.5,2.51468979 0.5,5 C0.5,7.48531021 2.51468979,9.5 5,9.5 Z"
              id="Stroke-59"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
