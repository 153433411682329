import React from 'react';

export default function GridIcon({ className = '' }) {
  return (
    <svg
      className={className}
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
    >
      <g
        id="Page-3"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      >
        <g
          id="supercluster_database_05-21-2021"
          transform="translate(-117.000000, -110.000000)"
          stroke="#000000"
        >
          <g id="listIcon" transform="translate(118.000000, 110.000000)">
            <path d="M18,14.5 L0,14.5" id="list-icon-copy-2"></path>
            <path d="M18,1 L0,1" id="list-icon"></path>
            <path d="M18,19 L0,19" id="list-icon-copy"></path>
            <path d="M18,10 L0,10" id="list-icon-copy-3"></path>
            <path d="M18,5.5 L1.42108547e-14,5.5" id="list-icon-copy-4"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
