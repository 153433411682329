import React, { FC, useState, useEffect, useMemo, useRef, useCallback } from 'react';
import classNames from 'classnames';
import {
  Agency,
  Astronaut,
  AstronautFilter,
  AstronautFilterResults,
  AstronautSortOption,
  astronautSortOptions,
  AwardType,
  Craft,
  DEFAULT_ADB_FILTERS,
  DEFAULT_ADB_SORT,
  deriveCraftMap,
  deriveNationMap,
  getMinifiedAstronauts,
  Nation,
  parseAdbData,
  SearchIndex,
  useSearch
} from 'superclient';
import { useIntersect } from '../state/use-intersect';
import usePrevious from '../state/use-previous';
import { useQueryBool, useQueryNumber, useQueryString } from '../state/use-query';
import { filterWorker } from '../workers/adb-worker.js';
import Dynamic from '../components/core/dynamic';
import MetaModule from '../components/core/meta-module';
import AstronautFiltersBar from '../components/astronauts/astronaut-filters-bar';
import AstronautSortBar from '../components/astronauts/astronaut-sort-bar';
import AstronautCard from '../components/astronauts/astronaut-card';
import AstronautCardLoading from '../components/astronauts/astronaut-card-loading';
import AstronautListCell from '../components/astronauts/astronaut-list-cell';
import AstronautListCellLoading from '../components/astronauts/astronaut-list-cell-loading';
import ClearCircle from '../components/svgs/clearCircle.js';
import { Superclient } from '../clients/superclient';

const PAGE_LENGTH = 9;

const loadingCells = [];
for (let i = 0; i < 15; i++) {
  loadingCells.push(i);
}

const TemplateAstronautIndex: FC<{
  pageContext: {
    agencies: Agency[];
    nations: Nation[];
    crafts: Craft[];
    awardTypes: AwardType[];
  };
}> = ({ pageContext: { agencies, nations, crafts, awardTypes } }) => {
  /** Query State **/
  const [useList, setUseList] = useQueryBool('list', false);
  const [searchText, setSearchTest] = useQueryString('q');
  const previousSearchText = usePrevious(searchText);
  const [sortBy, setSortBy] = useQueryString('sort', DEFAULT_ADB_SORT);
  const [sortAscending, setSortAscending] = useQueryBool('ascending', false);
  const [onlyShowAstronautsInSpace, setOnlyShowAstronautsInSpace] = useQueryBool(
    'inspace',
    false
  );
  const [limit, setLimit] = useQueryNumber('limit', PAGE_LENGTH);
  const limitRef = useRef<number>(PAGE_LENGTH);

  /** Data State **/

  const [worker, setWorker] = useState<any>(undefined);
  const [minifiedAstronauts, setMinifiedAstronauts] = useState<any[]>(undefined);
  const [minifiedMissions, setMinifiedMissions] = useState<any[]>(undefined);
  const [astronauts, setAstronauts] = useState<Astronaut[] | undefined>(undefined);
  const [filterOptionMap, setFilterOptionMap] = useState<
    Map<AstronautFilter, string[]>
  >(undefined);
  const [selectedFilterMap, setSelectedFilterMap] = useState(DEFAULT_ADB_FILTERS);
  const [craftMap, setCraftMap] = useState<Map<string, Craft>>(undefined);
  const [nationMap, setNationMap] = useState<Map<string, Nation>>(undefined);
  const [results, setResults] = useState<AstronautFilterResults>();

  useEffect(() => {
    Superclient.getMinifiedAstronauts().then((data) => {
      setMinifiedAstronauts(data.astronauts);
      setMinifiedMissions(data.missions);
    });
  }, []);

  const paramString = useMemo(() => {
    let paramString = '?';
    if (searchText && searchText.length > 0) {
      paramString = paramString + `q=${searchText}&`;
    }
    paramString = paramString + `sort=${sortBy}&`;
    paramString = paramString + `ascending=${sortAscending}&`;
    if (onlyShowAstronautsInSpace) {
      paramString = paramString + `inspace=${onlyShowAstronautsInSpace}&`;
    }
    if (useList) {
      paramString = paramString + `list=${useList}&`;
    }
    if (limit !== PAGE_LENGTH) {
      paramString = paramString + `limit=${limit}&`;
    }
    [...selectedFilterMap.keys()].forEach((field) => {
      paramString = paramString + `${field}=${selectedFilterMap.get(field)}&`;
    });

    return paramString;
  }, [sortBy, sortAscending, onlyShowAstronautsInSpace, limit, selectedFilterMap]);

  const hasMore = useMemo(() => {
    return limit < (results?.astronauts || []).length;
  }, [limit, results]);

  /** UI State **/

  const [filtersHeight] = useState(0);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const loadMoreRef = useRef<any>(null);
  const rafRef = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [setNode, entry] = useIntersect();

  useEffect(() => {
    setNode(loadMoreRef.current);
  }, [setNode]);

  useEffect(() => {
    if (entry?.isIntersecting) {
      setLimit(limitRef.current + PAGE_LENGTH);
    }
  }, [entry]);

  useEffect(() => {
    limitRef.current = limit;
  }, [limit]);

  useEffect(() => {
    rafRef.current = requestAnimationFrame(update);
    return () => cancelAnimationFrame(rafRef.current);
  }, []);

  const update = useCallback(() => {
    if (containerRef.current) {
      let headerHeight = 0;
      const headerElements = document.querySelectorAll('.astronauts_subheader');

      headerElements.forEach((element) => {
        if ((element as HTMLElement).offsetParent !== null) {
          headerHeight += element.clientHeight;
        }
      });
      containerRef.current.style.paddingTop = `${headerHeight}px`;
    }

    rafRef.current = requestAnimationFrame(update);
  }, []);

  /** Name Search **/

  const { isLoading: searchLoading, results: searchResults } = Superclient.useSearch(
    searchText,
    SearchIndex.Astronauts,
    100
  );

  const isLoading = useMemo(() => results === undefined || searchLoading, [
    results,
    searchLoading
  ]);

  const filteredAstronauts: Astronaut[] | undefined = useMemo(() => {
    if (
      !searchText ||
      searchText.length === 0 ||
      astronauts === undefined ||
      searchResults === undefined
    ) {
      return astronauts;
    }
    return searchResults
      .map((r) => astronauts.find((a) => r._id === a._id))
      .filter((a) => a !== undefined);
  }, [astronauts, searchResults, searchText]);

  useEffect(() => {
    if (
      (!previousSearchText || previousSearchText.length === 0) &&
      searchText &&
      searchText.length > 0
    ) {
      setSelectedFilterMap(new Map());
      setSortBy(undefined);
    }
  }, [searchText, previousSearchText]);

  /** Setup Worker **/

  useEffect(() => {
    const code = filterWorker.toString();
    const blob = new Blob(['(' + code + ')()']);
    setWorker(new Worker(URL.createObjectURL(blob)));
  }, []);

  useEffect(() => {
    if (!worker) return;

    const callback = (e) => setResults(e.data.results);
    worker.addEventListener('message', callback);

    return () => {
      worker.removeEventListener('message', callback);
    };
  }, [worker]);

  useEffect(() => {
    if (!worker || !filteredAstronauts) return;
    window.scrollTo(0, 0);
    worker.postMessage({
      astronauts: filteredAstronauts,
      selectedFilterMap,
      sortBy,
      sortAscending,
      onlyShowAstronautsInSpace
    });
  }, [
    worker,
    astronauts,
    selectedFilterMap,
    sortBy,
    sortAscending,
    onlyShowAstronautsInSpace,
    filteredAstronauts
  ]);

  /** Load Data **/

  useEffect(() => {
    if (
      !minifiedAstronauts ||
      !minifiedMissions ||
      !agencies ||
      !nations ||
      !crafts ||
      !awardTypes
    ) {
      return;
    }

    setNationMap(deriveNationMap(nations));
    setCraftMap(deriveCraftMap(crafts));

    const parsedData = parseAdbData(
      minifiedAstronauts,
      minifiedMissions,
      agencies,
      nations,
      crafts,
      awardTypes
    );
    setFilterOptionMap(parsedData.filterOptionsMap);
    setAstronauts(parsedData.astronauts);
  }, [minifiedAstronauts, minifiedMissions, agencies, nations, crafts, awardTypes]);

  /** Callbacks **/

  const onReset = useCallback(() => {
    setLimit(PAGE_LENGTH);
    setSelectedFilterMap(DEFAULT_ADB_FILTERS);
    setSortBy(DEFAULT_ADB_SORT);
    setSortAscending(true);
  }, []);

  const onSortChange = useCallback(
    (_sortBy: AstronautSortOption, _sortAscending: boolean) => {
      setSortBy(_sortBy);
      setSortAscending(_sortAscending);
    },
    []
  );

  return (
    <div className="astronaut_index">
      <MetaModule
        title="Astronaut Database"
        path="/astronauts"
        customImage={{
          src: 'https://www.supercluster.com/adb_share.jpg',
          width: 2992,
          height: 1496
        }}
        keywords="Astronaut Database, Launch Tracker"
        description="Explore the history and future of space travel with the most complete, searchable record of every living thing to leave planet Earth."
      />
      <Dynamic className="astronaut_index__nav bcw z3">
        <AstronautFiltersBar
          open={filtersOpen}
          searchText={searchText}
          filterOptionMap={filterOptionMap}
          selectedFiltersMap={selectedFilterMap}
          remainingFilterOptionsMap={results?.remainingFilterOptionsMap}
          onlyShowAstronautsInSpace={onlyShowAstronautsInSpace}
          inSpaceFilterSelectable={results?.inSpaceFilterSelectable}
          craftMap={craftMap}
          nationMap={nationMap}
          totalHumanCount={results?.totalHumanCount}
          totalNonHumanCount={results?.totalNonHumanCount}
          humanResultCount={results?.humanResultCount}
          nonHumanResultCount={results?.nonHumanResultCount}
          onReset={onReset}
          onToggleOpen={setFiltersOpen}
          onSearchTextChange={setSearchTest}
          onToggleOnlyShowAstronautsInSpace={setOnlyShowAstronautsInSpace}
          onSelectionChange={setSelectedFilterMap}
        />
        <AstronautSortBar
          sortOptions={astronautSortOptions}
          sortBy={sortBy as AstronautSortOption}
          sortAscending={sortAscending}
          selectedFiltersMap={selectedFilterMap}
          humanResultCount={results?.humanResultCount}
          nonHumanResultCount={results?.nonHumanResultCount}
          useList={useList}
          searchText={searchText}
          onSearchTextChange={setSearchTest}
          onToggleUseList={setUseList}
          onSortChange={onSortChange}
          onFilterChange={setSelectedFilterMap}
          onReset={onReset}
        />
      </Dynamic>
      <div className="rel z0" ref={containerRef}>
        <Dynamic
          className={classNames(
            'astronaut_index__content container--xl mxa f fr fw aifs pl15',
            {
              pr15: useList,
              py1: !useList,
              pt0: useList
            }
          )}
        >
          {results !== undefined &&
            results.astronauts
              .slice(0, limit)
              .map((astronaut, i) =>
                useList === true ? (
                  <AstronautListCell
                    key={`astro-grid-${i}`}
                    astronaut={astronaut}
                    paramString={paramString}
                  />
                ) : (
                  <AstronautCard
                    key={`astro-cell-${i}`}
                    astronaut={astronaut}
                    paramString={paramString}
                  />
                )
              )}
          {isLoading &&
            loadingCells.map((i) =>
              useList ? (
                <AstronautListCellLoading key={'loading-cell-' + i} index={i} />
              ) : (
                <AstronautCardLoading key={'loading-grid-' + i} index={i} />
              )
            )}
          {results && results.astronauts.length === 0 && (
            <div className="my2">
              <div className="h3">
                Houston, we’ve had a problem.
                <br />
                No astronauts match those parameters.
              </div>
              <div className="mt2 bau">
                To see astronauts, change some
                <br />
                parameters, or:
              </div>
              <div className="my2 f fr aic bau caps clickable" onClick={onReset}>
                RESET <ClearCircle className="ml05" />
              </div>
            </div>
          )}
        </Dynamic>
        <div className={classNames('x f aic jcc clickable py2')} ref={loadMoreRef}>
          <span
            className="bau small caps"
            style={{
              textDecoration: 'underline',
              opacity: hasMore ? 1 : 0
            }}
          >
            loading more...
          </span>
        </div>
      </div>
    </div>
  );
};

export default TemplateAstronautIndex;
